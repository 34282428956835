/**
 * Implementation of ArApiInterface
 *
 * Implemented as a global singleton, i.e. only a single instance of the ShapeDiver Viewer per web view may run in AR mode.
 * Current implementation of the touch controls can handle a single object only.
 *
 * @module ArApiARKit
 * @author Alexander Schiftner
 */

const THREE = require('../externals/three');
const GLOBAL_UTILS = require('../shared/util/GlobalUtils');
const COMMPLUGIN_ID = 'CommPlugin_1';


/**
* Factory for instances of the AR API, used once only for a global singleton
*/
let ArApiFactory = function (___refs) {

  const ArApiInterface = require('./ArApiInterface');
  const ArApiInterfaceInstance = new ArApiInterface();
  // const messagingConstants = require('../shared/constants/MessagingConstants');
  // const MessagePrototype = require('../shared/messages/MessagePrototype');
  const ArStatusType = require('./diagnostics/ArStatusType');
  const ArSettingsHooks = require('./ArSettingsHooks');
  const ArEvent = require('./ArEvent');
  const tempToRgb = require('./utils/ArApi.utils.js').temp2rgb;

  const observer = {
    events: [],
    listen: function (type, token, cb) {
      if (!this.events[type])
        this.events[type] = [];

      this.events[type].push({
        token: token,
        cb: cb
      });
    },
    raise: function (type, event) {
      if (this.events[type]) {
        this.events[type].forEach(function (e) {
          e.cb(event);
        });
      }
    },
    remove: function (token) {

      const parts = token.split(':');
      const type = parts[0];
      const id = parts[1];

      if (this.events[type]) {
        for (let i = 0; i < this.events[type].length; i++) {
          const e = this.events[type][i];
          if (e.token == id) {
            this.events[type].splice(i, 1);
            break;
          }
        }
      }

    }
  };

  let _viewportManager = ___refs.viewportManager,
      _container = Array.isArray(___refs.container) && ___refs.container.length > 0 ? ___refs.container[0] : ___refs.container,
      _apiResponse = ___refs.apiResponse,
      _sceneGeometryManager = ___refs.sceneGeometryManager,
      _arKitPreviousStatus = {},
      _anchors = null,
      _viewMatrix = new THREE.Matrix4(),
      _projectionMatrix = new THREE.Matrix4(),
      _viewportApi = null,
      _settingsHook = new ArSettingsHooks({
        viewportManager: ___refs.viewportManager,
        loggingHandler: ___refs.loggingHandler,
        settings: ___refs.settings,
      });

  /**
   * Accessor function for the viewport API, which might not be available yet at time of instantiation
   */
  let getViewportApi = (function () {
    return function () {
      if (!_viewportApi) {
        _viewportApi = _viewportManager.getApis()[0];
      }
      return _viewportApi;
    };
  })();

  /**
   * Accessor function for the container, which might not be available yet at time of instantiation
   */
  let getCamera = (function () {
    let _camera;
    return function () {
      if (!_camera)
        _camera = getViewportApi().camera;
      return _camera;
    };
  })();

  /////////////////////////////////////////////////////////////////
  //
  // ShapeDiver ARKit Bridge Handlers, called from the ShapeDiver iOS app
  //
  // These handlers are specific to ARKit and call further internal handlers,
  // which are independent from ARKit and will be reused when we implement a
  // further bridge to ARCore later.
  //
  /////////////////////////////////////////////////////////////////

  let SDVARKitBridge = {};

  let _matrixSdToAr = new THREE.Matrix4(), // transformation from ShapeDiver to ARKit coordinate system
      _matrixArToSd = new THREE.Matrix4(), // transformation from ARKit to ShapeDiver coordinate system
      _requestHandlers = {}; // helper for keeping track of requests to the iOS app

  // define transformation for mapping from ShapeDiver to ARKit coordinate system, and its inverse
  _matrixSdToAr.makeRotationAxis(new THREE.Vector3(1, 0, 0), -0.5 * Math.PI);
  _matrixArToSd.getInverse(_matrixSdToAr);

  // globally export ARKit bridge functions, which must be available to the iOS app
  window.SDVARKitBridge = SDVARKitBridge;

  /**
   * parse matrix received from embedding app
   */
  let _parseMatrix = function (str) {
    if (str.includes('\'') || str.includes('"'))
      return JSON.parse(str);
    return str.replace(/["\'\[\]]/g, '').split(',').map(n => parseFloat(n));
  };

  /**
   * ShapeDiver ARKit Bridge Handler, called from a ShapeDiver iOS app
   *
   * handler for receiving updates to the camera position, called based on every AR frame received
   *
   * this handler is called roughly using the framerate of arkit, by default 60 times per second,
   * see {@link https://developer.apple.com/documentation/scenekit/scnview/1621205-preferredframespersecond }
   *
   * @param {Array|String} viewMatrixArr array of coefficients of the camera view matrix in column-major order, or JSON encoded string of this array
   * @param {Array|String} projectionMatrixArr array of coefficients of the camera projection matrix in column-major order, or JSON encoded string of this array
   * @param {String} worldMappingStatus one of 'notAvailable', 'limited', 'extending', 'mapped', see {@link https://developer.apple.com/documentation/arkit/arframe/worldmappingstatus}
   * @param {String} trackingStatus one of 'notAvailable', 'limited', 'normal', see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate}
   * @param {String} trackingStatusReason one of 'initializing', 'excessiveMotion', 'insufficientFeatures', 'relocalizing', see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate/reason}
   */
  SDVARKitBridge.viewProjectionMatrixUpdate = function (viewMatrixArr, projectionMatrixArr, worldMappingStatus, trackingStatus, trackingStatusReason) {

    // forward worldMappingStatus to separate handler
    _worldMappingStatusHandler(worldMappingStatus, trackingStatus, trackingStatusReason);

    // parse matrix parameters
    if (typeof viewMatrixArr === 'string') viewMatrixArr = _parseMatrix(viewMatrixArr);
    if (typeof projectionMatrixArr === 'string') projectionMatrixArr = _parseMatrix(projectionMatrixArr);

    // convert matrices to THREE.Matrix4, apply transformation matrixArToSd to view matrix
    _viewMatrix.identity();
    _projectionMatrix.identity();

    _viewMatrix.fromArray(viewMatrixArr);
    _viewMatrix.multiply(_matrixSdToAr);
    _projectionMatrix.fromArray(projectionMatrixArr);

    // call internal handler
    _viewProjectionMatrixUpdate(_viewMatrix, _projectionMatrix, worldMappingStatus);
  };

  /**
   * ShapeDiver ARKit Bridge Handler, called from a ShapeDiver iOS app
   *
   * handler for receiving updates to the lighting estimation, typically called based on every AR frame received
   *
   * For additional information about the data received from ARKit, see {@link https://developer.apple.com/documentation/arkit/arlightestimate}
   *
   * this handler is called roughly using the framerate of arkit, by default 60 times per second,
   * see {@link https://developer.apple.com/documentation/scenekit/scnview/1621205-preferredframespersecond }
   *
   * @param {Number} ambientIntensity The estimated intensity, in lumens, of ambient light throughout the scene.
   *                                  In a well lit environment, this value is close to 1000.
   *                                  It typically ranges from 0 (very dark) to around 2000 (very bright).
   * @param {Number} ambientColorTemperature This specifies the ambient color temperature of the lighting in Kelvin (6500 corresponds to pure white).
   * @param {String} worldMappingStatus one of 'notAvailable', 'limited', 'extending', 'mapped', see {@link https://developer.apple.com/documentation/arkit/arframe/worldmappingstatus}
   * @param {String} trackingStatus one of 'notAvailable', 'limited', 'normal', see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate}
   * @param {String} trackingStatusReason one of 'initializing', 'excessiveMotion', 'insufficientFeatures', 'relocalizing', see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate/reason}
   */
  SDVARKitBridge.lightingEstimateUpdate = function (/*ambientIntensity, ambientColorTemperature, worldMappingStatus, trackingStatus, trackingStatusReason*/) {
    // TODO pass on to internal handler for updating lights (internal handler should update lights only if setting ar.enableLightingEstimation)
    // if (_settingsHook.getEnableLightingEstimationVal) {
    //   _viewportApi.lights.update({ type: 0, color: tempToRgb(ambientColorTemperature), intensity: ambientIntensity / 1000 })
    // }
  };

  /**
   * Helper for transforming an anchor object from ARKit coordinate space (y-axis up) to our coordinate space (z-axis up).
   * At the same time convert matrices and vectors to THREE objects.
   * Updates the anchor object in place.
   */
  let _convertAnchor = function (anchor) {

    let matrix4 = new THREE.Matrix4(),
        anchorPlane = anchor.plane;

    // apply coordinate transformation to anchor transformation
    matrix4.fromArray(anchor.transform);
    matrix4.multiply(_matrixSdToAr);
    matrix4.premultiply(_matrixArToSd);
    anchor.transform = matrix4;

    // if this is a plane anchor, apply coordinate transformation to plane center
    if (anchorPlane) {
      let vec3_center = new THREE.Vector3(),
          vec3_extent = new THREE.Vector3();

      vec3_center.fromArray(anchorPlane.center);
      vec3_center.applyMatrix4(_matrixArToSd);
      anchorPlane.center = vec3_center;

      vec3_extent.fromArray(anchorPlane.extent);
      vec3_extent.applyMatrix4(_matrixArToSd);
      anchorPlane.extent = vec3_extent;
    }
  };

  /**
   * ShapeDiver ARKit Bridge Handler, called from a ShapeDiver iOS app
   *
   * handler for receiving updates to the anchor positions, typically called based on every AR frame received, if there are anchors
   *
   * this handler is called roughly using the framerate of arkit, by default 60 times per second,
   * see {@link https://developer.apple.com/documentation/scenekit/scnview/1621205-preferredframespersecond }
   *
   * @param {String|Object<String, ARAnchorJson>} anchorsString JSON string encoding an object mapping UUIDs to ARAnchorJson objects
   * @param {String} worldMappingStatus one of 'notAvailable', 'limited', 'extending', 'mapped', see {@link https://developer.apple.com/documentation/arkit/arframe/worldmappingstatus}
   * @param {String} trackingStatus one of 'notAvailable', 'limited', 'normal', see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate}
   * @param {String} trackingStatusReason one of 'initializing', 'excessiveMotion', 'insufficientFeatures', 'relocalizing', see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate/reason}
   */
  SDVARKitBridge.anchorTransformUpdate = function (anchorsString, worldMappingStatus/*, trackingStatus, trackingStatusReason*/) {

    // parse anchor data
    _anchors = typeof anchorsString === 'string' ? JSON.parse(anchorsString) : anchorsString;

    // apply coordinate transformation matrixArToSd to anchors
    Object.keys(_anchors).forEach(function (uuid) {
      let anchor = _anchors[uuid];
      _convertAnchor(anchor);
    });

    // call internal handler
    _anchorTransformUpdate(_anchors, worldMappingStatus);
  };

  /**
   * ShapeDiver ARKit Bridge Handler, called from a ShapeDiver iOS app
   *
   * handler for image anchors created
   *
   * @param {String|ARAnchorJson} anchorString JSON string encoding an ARAnchorJson object
   */
  SDVARKitBridge.imageAnchorCreated = function (anchorString) {

    // parse anchor data
    let anchor = typeof anchorString === 'string' ? JSON.parse(anchorString) : anchorString;

    // apply coordinate transformation matrixArToSd to anchor
    _convertAnchor(anchor);

    // call internal handler
    _imageAnchorCreated(anchor);
  };

  /**
   * Helper for transforming a hit test result object from ARKit coordinate space (y-axis up) to our coordinate space (z-axis up).
   * At the same time convert matrices and vectors to THREE objects.
   * Updates the hit test result object in place.
   */
  let _convertHitTestResult = function (hitTestResult) {

    let matrix4_world = new THREE.Matrix4(),
      matrix4_local = new THREE.Matrix4(),
      anchor = hitTestResult.anchor;

    // apply coordinate transformation to hit test result world transformation
    matrix4_world.fromArray(hitTestResult.worldTransform);
    matrix4_world.multiply(_matrixSdToAr);
    matrix4_world.premultiply(_matrixArToSd);
    hitTestResult.worldTransform = matrix4_world;

    // apply coordinate transformation to hit test result world transformation
    matrix4_local.fromArray(hitTestResult.localTransform);
    matrix4_local.multiply(_matrixSdToAr);
    matrix4_local.premultiply(_matrixArToSd);
    hitTestResult.localTransform = matrix4_local;

    // if this is a plane anchor, apply coordinate transformation to plane center
    if (anchor) {
      _convertAnchor(anchor);
    }
  };

  /**
   * ShapeDiver ARKit Bridge Handler, called from a ShapeDiver iOS app
   *
   * handler for responses to 'hitTest' messages which we sent
   *
   * @param {String} requestId the unique request id that was specified by us when requesting the hitTest
   * @param {String|Array<ARHitTestResultJson>} hitTestResultsString JSON string encoding an array of ARHitTestResultJson objects
   */
  SDVARKitBridge.hitTestResponse = function (requestId, hitTestResultsString) {

    // check for handler for requestId
    let handler = _requestHandlers[requestId];
    if (handler) {
      delete _requestHandlers[requestId];
      if (hitTestResultsString) {
        // hit test successful, parse results
        let hitTestResults = typeof hitTestResultsString === 'string' ? JSON.parse(hitTestResultsString) : hitTestResultsString;
        // apply coordinate transformation matrixArToSd to hit test results before resolving
        hitTestResults.forEach(function (result) {
          _convertHitTestResult(result);
        });
        handler.resolve(hitTestResults);
      } else {
        // hit test failed
        handler.resolve([]);
      }
    }
  };

  /**
   * ShapeDiver ARKit Bridge Handler, called from a ShapeDiver iOS app
   *
   * handler for responses to 'registerAnchor' messages we send
   */
  SDVARKitBridge.registerAnchorResponse = function (requestId, anchorId) {

    // check for handler for requestId
    let handler = _requestHandlers[requestId];
    if (handler) {
      delete _requestHandlers[requestId];
      handler.resolve(anchorId);
    }
  };

  /**
   * ShapeDiver ARKit Bridge Handler, called from a ShapeDiver iOS app
   *
   * handler for responses to 'removeAnchor' messages we send
   */
  SDVARKitBridge.removeAnchorResponse = function (requestId, success) {

    // check for handler for requestId
    let handler = _requestHandlers[requestId];
    if (handler) {
      delete _requestHandlers[requestId];
      handler.resolve(success);
    }
  };

  /**
   * ShapeDiver ARKit Bridge Handler, called from a ShapeDiver iOS app
   *
   * handler for responses to 'pauseSession' messages we send
   */
  SDVARKitBridge.pauseSessionResponse = function (requestId) {

    // check for handler for requestId
    let handler = _requestHandlers[requestId];
    if (handler) {
      delete _requestHandlers[requestId];
      handler.resolve();
    }
  };

  /**
   * ShapeDiver ARKit Bridge Handler, called from a ShapeDiver iOS app
   *
   * handler for responses to 'runSession' messages we send
   */
  SDVARKitBridge.runSessionResponse = function (requestId) {

    // check for handler for requestId
    let handler = _requestHandlers[requestId];
    if (handler) {
      delete _requestHandlers[requestId];
      handler.resolve();
    }
  };

  SDVARKitBridge.webviewWillAppear = function() {

  };

  SDVARKitBridge.webviewDidAppear = function() {
    const event = new ArEvent(ArApiInterfaceInstance.EVENTTYPE.STATUS_WEBVIEW_DIDAPPEAR, {});
    observer.raise(ArApiInterfaceInstance.EVENTTYPE.STATUS_WEBVIEW_DIDAPPEAR, event);
  };

  SDVARKitBridge.webviewWillDisappear = function() {
    const event = new ArEvent(ArApiInterfaceInstance.EVENTTYPE.STATUS_WEBVIEW_WILLDISAPPEAR, {});
    observer.raise(ArApiInterfaceInstance.EVENTTYPE.STATUS_WEBVIEW_WILLDISAPPEAR, event);
  };

  SDVARKitBridge.webviewDidDisappear = function() {

  };


  /////////////////////////////////////////////////////////////////
  //
  // Handlers which are called by ARKit Bridge Handlers
  //
  /////////////////////////////////////////////////////////////////

  /**
   * internal handler for updating the camera
   *
   * @param {THREE.Matrix4} viewMatrix camera view matrix
   * @param {THREE.Matrix4} projectionMatrix camera projection matrix
   * @param {String} worldMappingStatus one of 'notAvailable', 'limited', 'extending', 'mapped', see {@link https://developer.apple.com/documentation/arkit/arframe/worldmappingstatus}
   */
  let _viewProjectionMatrixUpdate = function (viewMatrix, projectionMatrix/*, worldMappingStatus*/) {

    // update camera view and projection matrix if setting ar.enableCameraSync
    if (_settingsHook.getEnableCameraSyncVal) {
      getCamera().updateMatrices(viewMatrix, projectionMatrix);
    }

    // if object is being moved currently and setting ar.enableTouchControls, run a hitTest
    if (_settingsHook.getEnableCameraSyncVal && _settingsHook.getEnableTouchControlsVal) {
      _touchControlsHandleNewARFrame();
    }
  };

  /**
   * internal handler for the world mapping status
   *
   * This status should be used to show hints to the user, e.g. 'Move the camera', 'Move the camera more slowly', 'Try to start over', etc
   *
   * @param {String} worldMappingStatus one of 'notAvailable', 'limited', 'extending', 'mapped', see {@link https://developer.apple.com/documentation/arkit/arframe/worldmappingstatus}
   * @param {String} trackingStatus one of 'notAvailable', 'limited', 'normal', see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate}
   * @param {String} trackingStatusReason one of 'initializing', 'excessiveMotion', 'insufficientFeatures', 'relocalizing', see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate/reason}
   */
  let _worldMappingStatusHandler = function (worldMappingStatus, trackingStatus, trackingStatusReason) {
    // raise events STATUS_TRACKING and STATUS_MAPPING
    if (_arKitPreviousStatus.worldMappingStatus !== worldMappingStatus) {

      const event = new ArEvent(ArApiInterfaceInstance.EVENTTYPE.STATUS_MAPPING, {}, {
        worldMapping: worldMappingStatus,
        tracking: trackingStatus,
        trackingReason: trackingStatusReason
      });
      observer.raise(ArApiInterfaceInstance.EVENTTYPE.STATUS_MAPPING, event);
    }

    if (_arKitPreviousStatus.trackingStatus !== trackingStatus) {

      const event = new ArEvent(ArApiInterfaceInstance.EVENTTYPE.STATUS_TRACKING, {}, {
        worldMapping: worldMappingStatus,
        tracking: trackingStatus,
        trackingReason: trackingStatusReason
      });
      observer.raise(ArApiInterfaceInstance.EVENTTYPE.STATUS_TRACKING, event);
    }

    // status properties
    _arKitPreviousStatus.worldMappingStatus = worldMappingStatus;
    _arKitPreviousStatus.trackingStatus = trackingStatus;
    _arKitPreviousStatus.trackingStatusReason = trackingStatusReason;
  };

  let anchorsEvent = new ArEvent(ArApiInterfaceInstance.EVENTTYPE.ANCHOR_UPDATE, {});

  /**
   * internal handler for receiving updates to the anchor positions, typically called based on every AR frame received, if there are anchors
   *
   * this handler is called roughly using the framerate of arkit, by default 60 times per second,
   * see {@link https://developer.apple.com/documentation/scenekit/scnview/1621205-preferredframespersecond }
   *
   * @param {Object<String, ARAnchorJson>} anchors JSON string encoding an object mapping UUIDs to ARAnchorJson objects
   * @param {String} worldMappingStatus one of 'notAvailable', 'limited', 'extending', 'mapped', see {@link https://developer.apple.com/documentation/arkit/arframe/worldmappingstatus}
   */
  let _anchorTransformUpdate = function (anchors/*, worldMappingStatus*/) {

    // update object position only if setting ar.enableCameraSync
    if (_settingsHook.getEnableCameraSyncVal) {

      let placementAnchor = _getPlacementAnchor(COMMPLUGIN_ID);

      // whenever we receive data for our currently tracked anchor, update the transformation
      // we do not do this in case touch controls are active
      if (placementAnchor && anchors[placementAnchor] && !_touchesAreActive() ) {

        let anchor = anchors[placementAnchor];

        // we set the anchor transformation as the third one of the plugin, the first and second one are used for scaling and rotation
        _sceneGeometryManager.setPluginTransformation(COMMPLUGIN_ID, [null, null, anchor.transform], true);
      }
    }

    // raise event ANCHOR_UPDATE
    anchorsEvent.anchors = anchors;
    observer.raise(ArApiInterfaceInstance.EVENTTYPE.ANCHOR_UPDATE, anchorsEvent);
  };

  /**
   * internal handler for image anchors created
   *
   * @param {ARAnchorJson} anchor JSON string encoding an ARAnchorJson object
   */
  let _imageAnchorCreated = function (anchor) {
    // raise event ANCHOR_CREATE_IMAGE
    const event = new ArEvent(ArApiInterfaceInstance.EVENTTYPE.ANCHOR_CREATE_IMAGE, {}, {
      image: anchor
    });
    observer.raise(ArApiInterfaceInstance.EVENTTYPE.ANCHOR_CREATE_IMAGE, event);
  };


  /////////////////////////////////////////////////////////////////
  //
  // Wrapper functions for calling functions in the iOS ARKit app
  //
  // These functions are largely independent of ARKit
  //
  /////////////////////////////////////////////////////////////////

  let _lastRequestId = 0, // id counter for requests sent to the iOS app
      _framework; // AR framework which is available, used by getStatus

  /**
   * Find out which AR framework is available
   *
   * @return {String} one of 'none', 'arkit', 'arcore'
   */
  (() => {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['runSession']) {
      _framework = 'arkit';
    } else {
      _framework = 'none';
    }
  })();

  /**
   * Send a message to the specified handler in the iOS app
   *
   * @param {String} handler name of the handler
   * @param {Object} data data object to send to app, property 'requestId' will be injected
   * @return {Promise} Promise which resolves to
   */
  let _sendMessageToApp = function (handler, data) {
    return new Promise((resolve, reject) => {
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[handler]) {
        let requestId = 'id' + _lastRequestId++;
        data = data || {};
        data.requestId = requestId;
        _requestHandlers[requestId] = { resolve, reject };
        window.webkit.messageHandlers[handler].postMessage(data);
      } else {
        reject('Handler ' + handler + ' does not exist');
      }
    });
  };

  /**
   * Run a hit test using ARKit, based on the latest available ARFrame
   *
   * see {@link https://developer.apple.com/documentation/arkit/arhittestresult}
   * see {@link https://developer.apple.com/documentation/arkit/arframe/2875718-hittest}
   *
   * CAUTION: Be aware that the coordinates screenX and screenY are normalized screen coordinates.
   * The point (0,0) represents the top left corner of the screen, and the point (1,1) represents the bottom right corner.
   * As of now the AR view in the app always occupies the complete screen, and the webview on top of it occupies the complete screen as well.
   * The coordinates need to be given with respect to the complete screen therefore.
   *
   * @param {Number} screenX Normalized screen X coordinate (considering the complete screen of the device).
   * @param {Number} screenY Normalized screen Y coordinate (considering the complete screen of the device).
   * @param {String|String[]} types Optional. The types of hit-test result to search for. Array containing strings like one of the following:
   *                         'featurePoint', 'estimatedHorizontalPlane', 'estimatedVerticalPlane',
   *                         'existingPlane', 'existingPlaneUsingExtent', 'existingPlaneUsingGeometry'
   *                         see {@link https://developer.apple.com/documentation/arkit/arhittestresult/resulttype}
   * @return {Promise} Promise which resolves to an array of ARHitTestResultJson on success (may be empty).
   */
  let _hitTest = function (screenX, screenY, types) {
    // parameter sanity check
    if (screenX < 0 || screenX > 1 || screenY < 0 || screenY > 1) {
      return Promise.reject('Expects normalized screen coordinates');
    }
    if (typeof types === 'string') types = [types];
    else if (!Array.isArray(types)) types = [];
    // send message to app
    return _sendMessageToApp('hitTest', {
      screenX: screenX + '',
      screenY: screenY + '',
      types: types.join(';')
    });
  };

  /**
   * Register an anchor using ARKit
   *
   * Anchors should be registered for virtual objects placed in the scene, this helps tracking.
   * When moving a virtual object, remove the existing anchor and register a new one.
   *
   * see {@link https://developer.apple.com/documentation/arkit/aranchor}
   * see {@link https://developer.apple.com/documentation/arkit/arsession/2865612-add}
   *
   * Updates to the anchor position will be received by anchorTransformUpdate
   *
   * @param {THREE.Matrix4} transform Tranformation of new anchor
   * @param {String} name Optional descriptive name to use for anchor
   * @return {Promise} Promise which resolves to a unique identifier (String) of the newly added anchor.
   */
  let _registerAnchor = function (transform, name) {
    // parameter sanity check
    if (!transform || !transform.isMatrix4) {
      return Promise.reject('Provide a THREE.Matrix4');
    }
    if (typeof name !== 'string') name = '';
    // apply coordinate transformation to transform
    let transform_ar = new THREE.Matrix4();
    transform_ar.copy(transform);
    transform_ar.multiply(_matrixArToSd);
    transform_ar.premultiply(_matrixSdToAr);
    // send message to app
    return _sendMessageToApp('registerAnchor', {
      transform: JSON.stringify(transform_ar.toArray(), null, 0),
      name: name
    });
  };

  /**
   * Remove an anchor using ARKit
   *
   * Use this function to remove an anchor previously added using registerAnchor
   *
   * see {@link https://developer.apple.com/documentation/arkit/aranchor}
   * see {@link https://developer.apple.com/documentation/arkit/arsession/2865607-remove}
   *
   * @param {String} uuid Unique identifier of anchor to remove
   * @return {Promise} Promise which resolves to a boolean indicating whether the anchor could be removed.
   */
  let _removeAnchor = function (uuid) {
    // parameter sanity check
    if (typeof uuid !== 'string') {
      return Promise.reject('Anchor uuid must be a string');
    }
    if (!uuid) {
      return Promise.resolve(false);
    }
    // send message to app
    return _sendMessageToApp('removeAnchor', {
      uuid: uuid
    });
  };

  /**
   * Pause the ARKit session
   *
   * While paused, the session doesn't track device motion or capture scene imagery
   *
   * see {@link https://developer.apple.com/documentation/arkit/arsession/2865619-pause}
   *
   * @return {Promise} Promise which resolves once the session has been paused.
   */
  let _pauseSession = function () {
    // send message to app
    return _sendMessageToApp('pauseSession');
  };

  /**
   * Run the ARKit session after it has been paused, or reconfigure a running session, or reset tracking for a running session
   *
   * see {@link https://developer.apple.com/documentation/arkit/arsession/2875735-run}
   *
   * @param {String|String[]} planeDetection Optional. The types of planes that should be detected automatically. Defaults to no plane detection.
   *                         Array containing strings like one of the following: 'horizontal', 'vertical'
   *                         see {@link https://developer.apple.com/documentation/arkit/arworldtrackingconfiguration}
   * @param {String|String[]} options Optional. The options for running the session. No default options are set.
   *                         Array containing strings like one of the following: 'removeExistingAnchors', 'resetTracking'
   *                         see {@link https://developer.apple.com/documentation/arkit/arsession/runoptions}
   * @return {Promise} Promise which resolves once the session has been paused.
   */
  let _runSession = function (planeDetection, options) {

    // parameter sanity check
    if (typeof planeDetection === 'string') planeDetection = [planeDetection];
    else if (!Array.isArray(planeDetection)) planeDetection = [];

    if (typeof options === 'string') options = [options];
    else if (!Array.isArray(options)) options = [];

    // send message to app
    return _sendMessageToApp('runSession', {
      planeDetection: planeDetection.join(';'),
      options: options.join(';'),
    });
  };

  /////////////////////////////////////////////////////////////////
  //
  // Our default implementation of AR touch controls
  //
  /////////////////////////////////////////////////////////////////

  // touch controls
  let _twoFingerTouchAngleInitial = 0,
      _twoFingerTouchAngleAtLastTouchMove = 0,
      _twoFingerTouchAngleAtLastTouchEnd = 0,
      _firstFingerTouchIdentifier = '',
      _firstFingerTouchCoordinates = [],
      _rotationMatrix = new THREE.Matrix4(),
      _identityMatrix = new THREE.Matrix4(),
      _useAnchor = true, // if true then we register anchors for improving the tracking of placed objects
      _trackedAnchorUUID = '', // uuid of anchor used for positioning the object (FIXME extend to anchor uuid per runtimeId)
      _lastTouchHitTestResult, // last result of hit test initiated by the touch controls (FIXME extend to hit test result per runtimeId)
      _pluginMatrixRotation, // rotation matrix which was backed up when switching from AR to orbit mode last time
      _pluginMatrixAnchor; // anchor matrix which was backed up when switching from AR to orbit mode last time

  const AUTOMATIC_PLACEMENT_INTERVAL = 250; // amount of msec to wait between hit tests for automatic placement
  const AUTOMATIC_PLACEMENT_PROCESSES = {};

  /**
   * Check if touches are currently going on
   *
   * @return {Boolean} true if touches are currently going on
   */
  let _touchesAreActive = function() {
    return _firstFingerTouchIdentifier !== '';
  };

  /**
   * Callback hooked up with updates to the camera (device orientation), in order to handle currently active touches
   */
  let _touchControlsHandleNewARFrame = function () {
    // if object is being moved currently, run a hitTest
    if (_touchesAreActive()) {
      // TODO do this in a global chain of promises
      _hitTestAndSetTransformation(
        _firstFingerTouchCoordinates[0],
        _firstFingerTouchCoordinates[1]
      )
        .then(
          (hitTestResult) => {
            if (hitTestResult) {
              _lastTouchHitTestResult = hitTestResult;
            }
          }
        )
      ;
    }
  };

  /**
   * Get id of anchor currently used for object placement.
   *
   * @param {String} runtimeId Runtime id of the plugin for which the anchor currently used for object placement shall be returned, ignored for now
   * @return {String} Id of currently used anchor for object placement, empty string in case no anchor is set
   */
  let _getPlacementAnchor = function(runtimeId) {

    runtimeId = runtimeId || COMMPLUGIN_ID;

    if (!_trackedAnchorUUID)
      return '';
    return _trackedAnchorUUID;
  };

  /**
   * Reset anchor currently used for object placement.
   *
   * This results in the object going back to placement mode.
   *
   * @param {String} runtimeId Runtime id of the plugin for which the anchor currently used for object placement shall be reset, ignored for now
   * @return {String} Id of previously used anchor for object placement, empty string in case no anchor was set
   */
  let _resetPlacementAnchor = function(runtimeId) {

    runtimeId = runtimeId || COMMPLUGIN_ID;

    // get previous anchor id
    let prevAnchorId = _getPlacementAnchor(runtimeId);
    _trackedAnchorUUID = '';

    // raise event OBJECT_PLACEMENT
    const event = new ArEvent(ArApiInterfaceInstance.EVENTTYPE.OBJECT_PLACEMENT, {}, {
      runtimeId: runtimeId,
      anchorId: ''
    });
    observer.raise(ArApiInterfaceInstance.EVENTTYPE.OBJECT_PLACEMENT, event);

    // if enableCameraSync, hide object
    if (_settingsHook.getEnableCameraSyncVal) {
      _sceneGeometryManager.toggleGeometry([], [COMMPLUGIN_ID]);
      // if enableAutomaticPlacement, start automatic placement process
      if (_settingsHook.getEnableAutomaticPlacementVal) {
        _startAutomaticPlacement(COMMPLUGIN_ID);
      }
    }

    // return previous placement anchor id
    return prevAnchorId;
  };

  /**
   * Set id of anchor to be used for object placement.
   *
   * @param {String} anchorId Id of anchor to be used for object placement.
   * @param {String} runtimeId Runtime id of the plugin for which the anchor shall be set, ignored for now
   * @return {String} Id of previously used anchor for object placement, empty string in case no anchor was set
   */
  let _setPlacementAnchor = function(anchorId, runtimeId) {
    anchorId = anchorId || '';
    runtimeId = runtimeId || COMMPLUGIN_ID;

    // get previous anchor id
    let prevAnchorId = _getPlacementAnchor(runtimeId);
    _trackedAnchorUUID = anchorId;

    // raise event OBJECT_PLACEMENT
    const event = new ArEvent(ArApiInterfaceInstance.EVENTTYPE.OBJECT_PLACEMENT, {}, {
      runtimeId: runtimeId,
      anchorId: anchorId
    });
    observer.raise(ArApiInterfaceInstance.EVENTTYPE.OBJECT_PLACEMENT, event);

    // if enableCameraSync, show object
    if (_settingsHook.getEnableCameraSyncVal) {
      _sceneGeometryManager.toggleGeometry([COMMPLUGIN_ID], []);
    }

    // return previous placement anchor id
    return prevAnchorId;
  };

  /**
   * Create new anchor to be used for object placement using given transformation,
   * in case another anchor was used for placement before, remove it.
   *
   * @param {THREE.Matrix4} transform Tranformation of new anchor
   * @param {String} runtimeId Runtime id of the plugin for which the anchor shall be set
   * @return {String} Id of new anchor set for object placement
   */
  let _replacePlacementAnchor = function(transform, runtimeId) {

    runtimeId = runtimeId || COMMPLUGIN_ID;

    return _registerAnchor(transform)
      .then(
        function (anchorId) {
          let prevAnchorId = _setPlacementAnchor(anchorId, runtimeId);
          if ( prevAnchorId ) {
            // don't wait for removal of previous anchor
            _removeAnchor(prevAnchorId);
          }
          return anchorId;
        }
      )
    ;
  };

  /**
   * Start automatic object placement
   *
   * Recurses until a placement anchor has been set, automatic placement gets disabled, or camera synchronization gets disabled
   *
   * @param {String} runtimeId Runtime id of the plugin to start automatic placement for
   */
  let _startAutomaticPlacement = function(runtimeId) {

    runtimeId = runtimeId || COMMPLUGIN_ID;

    // check stop criteria
    if (AUTOMATIC_PLACEMENT_PROCESSES[runtimeId]) return;
    if (!_settingsHook.getEnableCameraSyncVal) return;
    if (!_settingsHook.getEnableAutomaticPlacementVal) return;
    if (_getPlacementAnchor(runtimeId)) return;

    // block the placement process for this runtimeId from being started several times
    AUTOMATIC_PLACEMENT_PROCESSES[runtimeId] = true;

    const recurse = () => {
      AUTOMATIC_PLACEMENT_PROCESSES[runtimeId] = setTimeout( () => {
        AUTOMATIC_PLACEMENT_PROCESSES[runtimeId] = false;
        _startAutomaticPlacement(runtimeId);
      }, AUTOMATIC_PLACEMENT_INTERVAL);
    };

    // check pausing criteria
    if (_touchesAreActive()) {
      recurse();
      return;
    }

    // hit test at center of screen
    return _hitTestAndSetTransformation(
      0.5,
      0.5
    )
      .then(
        (hitTestResult) => {
          if (hitTestResult) {
            return _replacePlacementAnchor(hitTestResult.worldTransform)
              .then(
                () => { AUTOMATIC_PLACEMENT_PROCESSES[runtimeId] = false; }
              )
            ;
          }
          else {
            recurse();
          }
        }
      )
    ;

  };

  /**
   * Do a hittest using given screenX and screenY coordinates, and reposition the object on success
   *
   * @param {Number} screenX Normalized screen X coordinate (considering the complete screen of the device).
   * @param {Number} screenY Normalized screen Y coordinate (considering the complete screen of the device).
   * @param {String|String[]} types Optional. The types of hit-test result to search for. Array containing strings like one of the following:
   *                         'featurePoint', 'estimatedHorizontalPlane', 'estimatedVerticalPlane',
   *                         'existingPlane', 'existingPlaneUsingExtent', 'existingPlaneUsingGeometry'
   *                         see {@link https://developer.apple.com/documentation/arkit/arhittestresult/resulttype}
   * @param {String} runtimeId Runtime id of the plugin whose transformation shall be updated, defaults to 'CommPlugin_1'
   * @return {Promise} Promise which resolves to an ARHitTestResultJson on success, otherwise undefined
   */
  let _hitTestAndSetTransformation = function (screenX, screenY, type, runtimeId) {

    // use hittest type according to setting ar.defaultHitTestType
    type = type || _settingsHook.getDefaultHitTestTypeVal;
    runtimeId = runtimeId || COMMPLUGIN_ID;

    // send hitTest message
    return _hitTest(
      screenX,
      screenY,
      type
    )
      .then(
        function (data) {

          if (Array.isArray(data) && data.length > 0) {

            // choose closest hit test result
            const hitTestResult = data[0];

            // update transformation of viewport
            // TODO ideally we should adjust the scene here, but for some reason this causes the object to appear jumping, to be clarified
            _sceneGeometryManager.setPluginTransformation(runtimeId, [null, null, hitTestResult.worldTransform], true);

            // return hit test result which was used
            return hitTestResult;
          }
        }
      )
    ;
  };

  /**
   * Given an array of two touches compute the direction vector between the touch coordinates
   * The touches are sorted by their touch.identifier
   */
  let _computeTwoFingerTouchDirection = function (touches) {
    // make sure we got two touches
    if (touches.length < 2) return [0, 0];
    // convert to array
    let arr = [];
    for (let i = 0, imax = touches.length; i < imax; i++) {
      arr.push(touches.item(i));
    }
    // sort by identifier
    arr.sort((a, b) => a.identifier < b.identifier);
    // compute direction
    return new THREE.Vector2(arr[1].screenX - arr[0].screenX, arr[1].screenY - arr[0].screenY);
  };

  /**
   * Handler for touchstart events
   *
   * Allows placement of complete scene by using a hitTest
   * Initializes data for rotation
   */
  let _handleTouchStart = function (event) {

    event.preventDefault();

    // two finger touches are used for rotating the object
    if (event.touches.length === 2) {
      let twoFingerTouchDirectionInitial = _computeTwoFingerTouchDirection(event.touches);
      _twoFingerTouchAngleInitial = twoFingerTouchDirectionInitial.angle();
      return;
    }

    // we ignore touchstart events which involve more than one touch
    // see https://developer.mozilla.org/en-US/docs/Web/Events/touchstart
    if (event.touches.length > 1)
      return;

    // there must be exactly one changed touch
    let touch = event.changedTouches.item(0);

    // compute normalized screen coordinates
    let screenX = touch.screenX / window.innerWidth,
        screenY = touch.screenY / window.innerHeight;

    // a single finger touch started, remember its id and coordinates
    _firstFingerTouchIdentifier = touch.identifier;
    _firstFingerTouchCoordinates = [screenX, screenY];

    // send hitTest message and transform objects
    // TODO do this using a global chain of promises
    _hitTestAndSetTransformation(
      screenX,
      screenY
    )
      .then(
        (hitTestResult) => {
          if (hitTestResult) {
            _lastTouchHitTestResult = hitTestResult;
          }
        }
      )
    ;

  };

  /**
   * Handler for touchmove events
   *
   * Allows rotation of objects
   */
  let _handleTouchMove = function (event) {

    event.preventDefault();

    let changedTouches = event.changedTouches,
        touches = event.touches;

    // two finger touches are used for rotating the object
    if (touches.length === 2) {

      // get updated direction vector between touch points
      let twoFingerTouchDirection = _computeTwoFingerTouchDirection(touches);

      // compute angle in radians with respect to the positive x-axis
      let twoFingerTouchAngleDelta = _twoFingerTouchAngleInitial - twoFingerTouchDirection.angle();
      _twoFingerTouchAngleAtLastTouchMove = twoFingerTouchAngleDelta + _twoFingerTouchAngleAtLastTouchEnd;

      // computation rotation transformation
      _rotationMatrix.makeRotationZ(_twoFingerTouchAngleAtLastTouchMove);

      // set rotating transformation as second transformation of COMMPLUGIN_ID if setting enableTouchControlRotation,
      // the first transformation is used for scaling
      if (_settingsHook.getEnableTouchControlRotationVal) {
        _sceneGeometryManager.setPluginTransformation(COMMPLUGIN_ID, [null, _rotationMatrix], true);
      }
    }

    // check if the touch for moving has ended
    for (let i = 0, imax = changedTouches.length; i < imax; i++) {
      if (changedTouches.item(i).identifier === _firstFingerTouchIdentifier) {

        // compute normalized screen coordinates
        let touch = changedTouches.item(i);
        let screenX = touch.screenX / window.innerWidth,
            screenY = touch.screenY / window.innerHeight;

        // remember coordinates
        _firstFingerTouchCoordinates = [screenX, screenY];

        // send hitTest message and transform objects
        // TODO do this using a global chain of promises
        _hitTestAndSetTransformation(
          screenX,
          screenY
        )
          .then(
            (hitTestResult) => {
              if (hitTestResult) {
                _lastTouchHitTestResult = hitTestResult;
              }
            }
          )
        ;

        break;
      }
    }

  };

  let _getStatus = function () {
    return new ArStatusType(
      _framework,
      _anchors,
      _arKitPreviousStatus.trackingStatus,
      _arKitPreviousStatus.trackingStatusReason,
      _arKitPreviousStatus.worldMappingStatus
    );
  };

  /**
   * Handler for touchend and touchcancel events
   *
   */
  let _handleTouchEndOrCancel = function (event) {

    event.preventDefault();

    let changedTouches = event.changedTouches;

    // save last rotation angle
    _twoFingerTouchAngleAtLastTouchEnd = _twoFingerTouchAngleAtLastTouchMove;

    // check if the touch for moving has ended
    let firstFingerTouchEnds = false;
    for (let i = 0, imax = changedTouches.length; i < imax; i++) {
      if (changedTouches.item(i).identifier === _firstFingerTouchIdentifier) {
        firstFingerTouchEnds = true;
        break;
      }
    }

    // if moving has ended, place an anchor and enable tracking, and remove previously added anchor
    if (firstFingerTouchEnds && _useAnchor) {

      // adjust the scene
      _viewportManager.threeDManager.adjustScene();

      // TODO do this in a global chain of promises
      _replacePlacementAnchor(_lastTouchHitTestResult.worldTransform)
        .then(
          function () {
            _firstFingerTouchIdentifier = '';
          }
        )
      ;
    }
    else if (firstFingerTouchEnds)
    {
      _firstFingerTouchIdentifier = '';
    }

  };

  // add touch event handlers
  if (_container) {

    let touchEventListenersEnabled = false;

    // bind and call a function
    _settingsHook.onSettingChange = function (s, v) {

      if (s === 'enableCameraSync') {
        // store/restore plugin and viewport matrices when switching on/off AR camera sync
        if (!v) {
          // save matrices per plugin, and reset them to identity
          let matrices = _sceneGeometryManager.getPluginTransformation(COMMPLUGIN_ID);
          if ( Array.isArray(matrices) && matrices.length >= 3 ) {
            _pluginMatrixRotation = matrices[1];
            _pluginMatrixAnchor = matrices[2];
          }
          _sceneGeometryManager.setPluginTransformation(COMMPLUGIN_ID, [null, _identityMatrix, _identityMatrix]);
          // disable continuous rendering due to AR
          _viewportManager.threeDManager.renderingHandler.unregisterForContinuousRendering('AR');
          // if object had not been placed yet it is probably still hidden, show it
          if ( !_getPlacementAnchor(COMMPLUGIN_ID) ) {
            _sceneGeometryManager.toggleGeometry([COMMPLUGIN_ID], []);
          }
        } else {
          // restore matrices per plugin, if they were saved before
          if (_pluginMatrixRotation && _pluginMatrixAnchor) {
            _sceneGeometryManager.setPluginTransformation(COMMPLUGIN_ID, [null, _pluginMatrixRotation, _pluginMatrixAnchor]);
          }
          // enable continuous rendering due to AR
          _viewportManager.threeDManager.renderingHandler.registerForContinuousRendering('AR');
          // make sure orbit controls are disabled if camera gets synchronized from AR
          if (v) {
            getViewportApi().updateSettingAsync('camera.enableCameraControls', !v);
          }
          // if object has not been placed yet, hide object
          if ( !_getPlacementAnchor(COMMPLUGIN_ID) ) {
            _sceneGeometryManager.toggleGeometry([], [COMMPLUGIN_ID]);
            // if enableAutomaticPlacement, start automatic placement process
            if (_settingsHook.getEnableAutomaticPlacementVal) {
              _startAutomaticPlacement(COMMPLUGIN_ID);
            }
          }
        }
      }
      else if (s === 'enableAutomaticPlacement' && v) {
        _startAutomaticPlacement(COMMPLUGIN_ID);
      }

      // the touch controls only make sense in case AR camera sync is enabled
      if (_settingsHook.getEnableCameraSyncVal && _settingsHook.getEnableTouchControlsVal && !touchEventListenersEnabled) {
        _container.addEventListener('touchstart', _handleTouchStart, false);
        _container.addEventListener('touchmove', _handleTouchMove, false);
        _container.addEventListener('touchend', _handleTouchEndOrCancel, false);
        _container.addEventListener('touchcancel', _handleTouchEndOrCancel, false);
        touchEventListenersEnabled = true;
      } else {
        _container.removeEventListener('touchstart', _handleTouchStart, false);
        _container.removeEventListener('touchmove', _handleTouchMove, false);
        _container.removeEventListener('touchend', _handleTouchEndOrCancel, false);
        _container.removeEventListener('touchcancel', _handleTouchEndOrCancel, false);
        _firstFingerTouchIdentifier = '';
        touchEventListenersEnabled = false;
      }

    };

    // viewport will for sure be available in next run of event loop
    setTimeout(function () {
      if (getViewportApi()) {
        _settingsHook.onSettingChange('enableCameraSync', _settingsHook.getEnableCameraSyncVal);
      }
    }, 0);
  }

  /////////////////////////////////////////////////////////////////
  //
  // Exposed AR API
  //
  /////////////////////////////////////////////////////////////////

  /**
   * @extends module:ArApiInterface~ArApiInterface
   * @lends module:ArApiARKit~ArApi
   */
  class ArApi extends ArApiInterface {

    /**
     * ### ShapeDiver Viewer - AR API
     *
     * @constructs module:ArApiARKit~ArApi
     */
    constructor() {
      super();
    }

    /** @inheritdoc */
    hitTest(screenX, screenY, type) {
      return _hitTest(screenX, screenY, type);
    }

    /** @inheritdoc */
    registerAnchor(transform, name) {
      return _registerAnchor(transform, name);
    }

    /** @inheritdoc */
    removeAnchor(uuid) {
      return _removeAnchor(uuid);
    }

    /** @inheritdoc */
    pauseSession() {
      return _pauseSession();
    }

    /** @inheritdoc */
    runSession(planeDetection, options) {
      return _runSession(planeDetection, options);
    }

    /** @inheritdoc */
    getStatus() {
      return _apiResponse(null, _getStatus());
    }

    /** @inheritdoc */
    addEventListener(type, callback) {
      // check if event type is supported

      if (!Object.keys(ArApiInterfaceInstance.EVENTTYPE).find((k) => (ArApiInterfaceInstance.EVENTTYPE[k] === type)))
        return _apiResponse('Unsupported event type');

      const token = GLOBAL_UTILS.createRandomId();
      observer.listen(type, token, callback);
      return _apiResponse(null, { token: type + ':' + token });
    }

    /** @inheritdoc */
    removeEventListener(token) {
      return _apiResponse(null, observer.remove(token));
    }

    /** @inheritdoc */
    getPlacementAnchor(runtimeId) {
      return _apiResponse(null, _getPlacementAnchor(runtimeId));
    }

    /** @inheritdoc */
    resetPlacementAnchor(runtimeId) {
      return _apiResponse(null, _resetPlacementAnchor(runtimeId));
    }

    /** @inheritdoc */
    setPlacementAnchor(anchorId, runtimeId) {
      return _apiResponse(null, _setPlacementAnchor(anchorId, runtimeId));
    }

  }

  return new ArApi();
};

// create global singleton instance or return it
let _ArApiInstance;
module.exports = function (___refs) {
  if (!_ArApiInstance) {
    _ArApiInstance = ArApiFactory(___refs);
  }
  return _ArApiInstance;
};
